@charset "utf-8";

/* ---------------------------------------------
	Effect
--------------------------------------------- */
//- Shutter Out Horizontal
.hvr-shutrout {
	background: transparent;
	color: #fff;
	display: inline-block !important;
	position: relative;
	transition-property: color;
	transition-duration: 0.3s;
	vertical-align: middle;
	z-index: 0;
	&::after {
		background: #fff;
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transition: all 0.3s;
		width: 0%;
		z-index: -1;
	}
	&:hover,
	&:focus,
	&:active {
		color: $main-color;
		opacity: 1;
		&::after {
			width: 100%;
		}
	}
	&.wht {
		&::after {
			background: $main-color3;
		}
		&:hover,
		&:focus,
		&:active {
			color: $main-color2;
		}
	}
}

//- Rectangle Out
.hvr-rectout {
	background: transparent;
	color: #fff;
	display: inline-block !important;
	font-size: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
	position: relative;
	transform: perspective(1px) translateZ(0);
	transition-duration: 0.3s;
	transition-property: color;
	vertical-align: middle;
	width: 20em;
	z-index: 0;
	&::after {
		background: rgba($main-bg-color, 0.9);
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: scale(0);
		transition-duration: 0.3s;
		transition-property: transform;
		transition-timing-function: ease-out;
		z-index: -1;
	}
	&:hover,
	&:focus,
	&:active {
		color: $main-color;
		&::after {
			transform: scale(1);
		}
	}
}
