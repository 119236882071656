@charset "utf-8";

/* ---------------------------------------------
	Home
--------------------------------------------- */
.p-top {
	//- HOME > メインビジュアル
	&-mv {
		position: relative;
		width: 100vw;
		height: calc(100vh - 188px);
		min-height: 500px;
		@include mq(lg) {
			height: 780px;
		}
	}
	//- .p-top__slider
	&__slider {
		width: 100%;
		height: 100%;
		z-index: 0;
		@include mq(lg) {
			height: 780px;
		}
		.slick-slider,
		.slick-list,
		.slick-track,
		.slick-slide {
			width: 100%;
			height: 100%;
		}
		.slick-slide {
			position: relative;
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			a.u-over {
				@include mq_down(lg) {
					display: none;
				}
			}
		}
		//- .p-top__slider__overlay
		&__overlay {
			width: 82vw;
			max-width: 82vw;
			height: 17vw;
			@include absolute_top_center($top: 18%);
			z-index: 1;
			@include mq() {
				width: 70vw;
				max-width: 70vw;
				height: 14.5vw;
				top: 15%;
			}
			@include mq(lg) {
				width: 100%;
				max-width: 715px;
				height: 147px;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		//- .p-top__slider__name
		&__name {
			&--bottom {
				width: calc(100% - 10vw);
				max-width: 1440px;
				margin: auto;
				@include absolute_bottom_center($bottom: 40px);
				z-index: 0;
				@include mq_down(lg) {
					width: calc(100% - 4vw);
				}
				p {
					color: $main-color2;
					font-family: $font-family-en;
					font-size: calc(10px + 5 * (100vw - 320px) / 960);
					font-weight: $font-weight-bolder;
					line-height: 1.4;
					margin-bottom: 0;
					@include mq(xl) {
						@include font-size(15);
					}
					&:nth-of-type(2) {
						margin-top: 0.3em;
					}
					span {
						display: block;
						&:nth-of-type(1) {
							font-size: 80%;
						}
					}
				}
			}
			&--side {
				writing-mode: vertical-rl;
				@include absolute_bottom_left($bottom: calc(50px + 2.5em), $left: 50%);
				transform: translateX(calc(-48vw)) rotate(-180deg);
				@include mq_down(lg) {
					display: none;
				}
				@include mq(xxxl) {
					transform: translateX(-760px) rotate(-180deg);
				}
				p {
					color: $main-color2;
					font-family: $font-family-en;
					font-size: calc(8px + 3 * (100vw - 320px) / 960);
					font-weight: $font-weight-bolder;
					line-height: 1;
					margin-bottom: 0;
					span {
						font-size: 110%;
					}
				}
			}
		}
		.slick-counter {
			color: $main-color2;
			font-size: calc(8px + 3 * (100vw - 320px) / 960);
			font-family: $font-family-en;
			font-weight: $font-weight-bold;
			line-height: 1;
			letter-spacing: 0;
			margin-bottom: 0;
			writing-mode: vertical-rl;
			position: absolute;
			@include absolute_bottom_left($bottom: 45px, $left: 50%);
			transform: translateX(calc(-48vw)) rotate(-180deg);
			z-index: 3;
			@include mq_down(lg) {
				display: none;
			}
			@include mq(xxxl) {
				transform: translateX(-760px) rotate(-180deg);
			}
		}
		& > .slick-counter {
			display: none;
		}
		.slider {
			&-dots {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				@include absolute_bottom_right($bottom: 38px, $right: 2vw);
				width: 20%;
				max-width: 225px;
				@include mq_down(lg) {
					width: 40%;
				}
				@include mq(xxl) {
					right: 50%;
					transform: translateX(calc(670px + 3vw));
				}
				@include mq(xxxl) {
					transform: translateX(calc(760px));
				}
				li {
					width: 15%;
					position: relative;
					&::before {
						content: "";
						display: inline-block;
						width: 100%;
						height: 2px;
						background: $main-color4;
						@include absolute_bottom_left($bottom: -2px);
						opacity: 0.4;
					}
					&::after {
						content: "";
						display: inline-block;
						width: 100%;
						height: 2px;
						background: $main-color2;
						@include absolute_bottom_left($bottom: -2px);
						transform-origin: top left;
						transform: scaleX(0);
						transition-duration: 1800ms;
						transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
					}
					a {
						font-family: $font-family-en;
						font-size: calc(8px + 2 * (100vw - 320px) / 680);
						font-weight: $font-weight-bold;
						.slider-dots-txt {
							color: $main-color3;
							padding-bottom: 0.6em;
						}
					}
					&.slick-active {
						&::after {
							transform: scaleX(1);
						}
						a {
							.slider-dots-txt {
								color: $main-color2;
							}
						}
					}
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						&.slick-active {
							&::after {
								@include mq(lg) {
									background: $main-color;
								}
							}
							a {
								.slider-dots-txt {
									@include mq(lg) {
										color: $main-color;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	//- スライダー.yadoの場合
	.slick-slide.yado {
		background-color: $main-bg-color3;
		.p-top__slider__name--bottom,
		.p-top__slider__name--side {
			p {
				color: $main-color3;
			}
		}
		.p-top__slider__name--bottom {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			bottom: 35px;
			@include mq_down() {
				display: none;
			}
			p {
				&:first-of-type {
					font-size: calc(21px + 16 * (100vw - 320px) / 1280);
					padding-right: 20px;
					@include mq(xxxl) {
						@include font-size(38);
					}
				}
			}
		}
		.slick-counter {
			color: $main-color3;
		}
		.p-top__slider__flex {
			width: 100%;
			max-width: 1600px;
			margin: auto;
			padding: 2vw 6vw;
			@include mq(lg) {
				display: flex;
				flex-flow: row wrap;
				align-items: stretch;
				min-height: 720px;
				padding: 0;
			}
			&__txt {
				position: relative;
				padding-top: 4vw;
				z-index: 1;
				@include mq(lg) {
					width: 35%;
					max-width: 35%;
					height: 85%;
					padding-left: 6vw;
				}
				@include mq(xxl) {
					padding-left: 80px;
				}
				@include mq(xxxl) {
					padding-top: 50px;
				}
				span {
					display: inline-block;
					@include mq_down(sm) {
						display: inline;
					}
				}
				h3 {
					font-size: calc(16px + 21 * (100vw - 320px) / 1280);
					line-height: 1.8;
					margin-top: 1em;
					@include mq_down(lg) {
						color: $main-color2;
					}
					@include mq_down(xxs) {
						font-size: 5vw;
					}
					@include mq(xxxl) {
						@include font-size(37);
					}
					span {
						display: inline-block;
						@include mq_down(sm) {
							display: block;
						}
					}
				}
				p {
					font-size: calc(13px + 1 * (100vw - 320px) / 960);
					@include mq_down(lg) {
						color: $main-color2;
					}
					@include mq_down(xxs) {
						font-size: 3.5vw;
						line-height: 6vw;
					}
				}
				a.c-more {
					z-index: 3;
					@include mq_down(lg) {
						color: $main-color2;
						border-bottom-color: $main-color2;
						margin-top: -1em;
						&::after {
							border-top-color: $main-color2;
							border-right-color: $main-color2;
						}
					}
					@include mq_down(xxs) {
						font-size: 3vw;
					}
				}
			}
			.p-top__slider__header {
				h2 {
					line-height: 1.1;
					span {
						display: block;
						font-family: $font-family-en;
						&:nth-of-type(1) {
							color: $main-color3;
							font-size: calc(11px + 5 * (100vw - 320px) / 1280);
							padding-bottom: 1em;
							@include mq_down(lg) {
								color: $main-color4;
							}
							@include mq_down(xxs) {
								font-size: 2vw;
							}
							@include mq(xxxl) {
								@include font-size(16px);
							}
						}
						&:nth-of-type(2) {
							font-size: calc(27px + 13 * (100vw - 320px) / 1280);
							font-weight: $font-weight-bolder;
							letter-spacing: 0;
							@include mq_down(lg) {
								color: $main-color2;
							}
							@include mq_down(xxs) {
								font-size: 7vw;
							}
							@include mq(xxxl) {
								@include font-size(40);
							}
						}
						&:nth-of-type(3) {
							font-size: calc(12px + 6 * (100vw - 320px) / 1280);
							font-weight: $font-weight-bold;
							letter-spacing: 0.1em;
							padding-top: 0.5em;
							@include mq_down(lg) {
								color: $main-color2;
							}
							@include mq_down(xxs) {
								font-size: 2.5vw;
							}
							@include mq(xxxl) {
								@include font-size(18px);
							}
						}
					}
				}
			}
			&__img {
				width: 100%;
				height: 100%;
				@include bg("n", no, cb, cover);
				@include absolute_top_left;
				z-index: 0;
				@include mq(lg) {
					background-size: cover;
					width: 60%;
					max-width: 960px;
					height: 85%;
					right: 0;
					left: inherit;
				}
				@include mq(xxxl) {
					right: calc(50% - 800px);
				}
				&::before {
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					background-color: rgba($main-color, 0.3);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 117px auto;
					@include absolute_top_left;
					opacity: 1;
					z-index: 2;
					@include mq(lg) {
						background-color: transparent;
						opacity: 0;
						transition: opacity 0.5s;
					}
				}
				@include mq(lg) {
					&:hover {
						z-index: 1;
						&::before {
							background-color: rgba($main-color, 0.85);
						}
					}
				}
				& > a {
					display: block;
					width: 100%;
					height: 100%;
					@include absolute_top_right;
					z-index: 3 !important;
					@include mq_down(lg) {
						display: none;
					}
				}
			}
		}
		@include mq(lg) {
			&:hover {
				.p-top__slider__flex__txt {
					a.c-more::after {
						right: -3px;
					}
				}
				.p-top__slider__flex__img {
					&::before {
						background-color: rgba($main-color, 0.85);
					}
				}
			}
		}
		&:nth-of-type(3) {
			.p-top__slider__flex__img {
				background-image: url($imgDir + "home/topslider/wakkanupuri.jpg");
			}
			@include mq(lg) {
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_wakkanupuri_w.svg");
							opacity: 1;
						}
					}
				}
			}
			@include mq_down(lg) {
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_wakkanupuri_w.svg");
							background-position: right 6vw top 6vw;
							opacity: 1;
						}
					}
				}
			}
		}
		&:nth-of-type(4) {
			.p-top__slider__flex__img {
				background-image: url($imgDir + "home/topslider/rera.jpg");
			}
			@include mq(lg) {
				//-イメージ無しの場合
				//-.p-top__slider__flex__img {
				//-&::after {
				//-content: "COMING SOON";
				//-display: inline-block;
				//-width: 100%;
				//-height: 1em;
				//-color: rgba($main-color2, 0.6);
				//-@include font-size(20);
				//-font-weight: $font-weight-semibold;
				//-text-align: center;
				//-@include absolute_top_left($top: 50%, $left: 50%);
				//-transform: translate(-50%, -50%);
				//-z-index: 3 !important;
				//-}
				//-}
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_rera.svg");
							opacity: 1;
						}
						&::after {
							display: none;
						}
					}
				}
			}
			@include mq_down(lg) {
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_rera.svg");
							background-position: right 6vw top 6vw;
							opacity: 1;
						}
					}
				}
			}
		}
		&:nth-of-type(5) {
			.p-top__slider__flex__img {
				background-image: url($imgDir + "home/topslider/fure.jpg");
			}
			@include mq(lg) {
				//-イメージ無しの場合
				//-.p-top__slider__flex__img {
				//-&::after {
				//-content: "COMING SOON";
				//-display: inline-block;
				//-width: 100%;
				//-height: 1em;
				//-color: rgba($main-color2, 0.6);
				//-@include font-size(20);
				//-font-weight: $font-weight-semibold;
				//-text-align: center;
				//-@include absolute_top_left($top: 50%, $left: 50%);
				//-transform: translate(-50%, -50%);
				//-z-index: 3 !important;
				//-}
				//-}
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_fure.svg");
							opacity: 1;
						}
						&::after {
							display: none;
						}
					}
				}
			}
			@include mq_down(lg) {
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_fure.svg");
							background-position: right 6vw top 6vw;
							opacity: 1;
						}
					}
				}
			}
		}
		&:nth-of-type(6) {
			.p-top__slider__flex__img {
				background-image: url($imgDir + "home/topslider/ipe.jpg");
				&::before {
					background-size: 45vw auto;
					@include mq(xs) {
						background-size: 40vw auto;
					}
					@include mq(sm) {
						background-size: 240px auto;
					}
				}
			}
			@include mq(lg) {
				//-イメージ無しの場合
				.p-top__slider__flex__img {
					&::after {
						content: "COMING SOON";
						display: inline-block;
						width: 100%;
						height: 1em;
						color: rgba($main-color2, 0.6);
						@include font-size(20);
						font-weight: $font-weight-semibold;
						text-align: center;
						@include absolute_top_left($top: 50%, $left: 50%);
						transform: translate(-50%, -50%);
						z-index: 3 !important;
					}
				}
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_ipe.svg");
							opacity: 1;
						}
						//-イメージ無しの場合
						&::after {
							display: none;
						}
					}
				}
			}
			@include mq_down(lg) {
				&:hover {
					.p-top__slider__flex__img {
						&::before {
							background-image: url($imgDir + "common/logo_ipe.svg");
							background-position: right 6vw top 6vw;
							opacity: 1;
						}
					}
				}
			}
		}

		&.pre {
			background: $main-color;
			.p-top__slider__flex {
				&__txt,
				&__img {
					display: none;
				}
				& > div {
					width: 30%;
					max-width: 180px;
					height: 0;
					padding-bottom: 78%;
					position: absolute;
					top: 20%;
					left: 50%;
					transform: translateX(-50%);
					z-index: 10;
					@include mq(sm) {
						height: 475px;
						padding-bottom: 0;
					}
					p {
						color: $main-color2;
						line-height: 1.6;
						text-align: center;
						span {
							display: block;
							&:nth-of-type(1) {
								font-family: $font-family-en;
								@include font-size(39);
								font-weight: $font-weight-bold;
								@include mq_down(xs) {
									font-size: 7vw;
								}
							}
							&:nth-of-type(2) {
								@include font-size(21);
								font-family: $font-family-en2;
								font-weight: $font-weight-base !important;
								@include mq_down(xs) {
									font-size: 4vw;
								}
							}
						}
					}
					img {
						width: 100%;
						max-width: 180px;
						height: auto;
						margin-top: 80px;
					}
				}
			}
			&.ipe {
				.p-top__slider__flex {
					& > div {
						max-width: 300px;
						padding-bottom: 25.8%;
						img {
							max-width: 300px;
						}
					}
				}
			}
		}
	}

	//- HOME > NEWS
	&-news {
		position: relative;
		&__list {
			width: 100%;
			max-width: 350px;
			margin-right: auto;
			margin-left: auto;
			@include mq(sm) {
				max-width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: flex-start;
				margin-top: 80px;
			}
			article {
				position: relative;
				margin-bottom: 100px;
				@include mq(sm) {
					width: 48%;
				}
				@include mq(lg) {
					width: 23%;
					margin-bottom: 0;
				}
				div {
					&.img {
						width: 100%;
						height: 0;
						padding-bottom: 70%;
						overflow: hidden;
						position: relative;
						margin-bottom: 20px;
						img {
							width: 100%;
							height: auto;
							@include centering;
							transition: all 0.3s;
						}
					}
					&.head {
						height: 4em;
						padding-bottom: 0;
						h4 {
							font-family: $font-family-base;
							line-height: 1.4;
							margin-bottom: 0;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						time {
							color: #bbb;
							font-family: $font-family-en;
						}
					}
					&.content {
						height: 5.5em;
						position: relative;
						overflow: hidden;
						white-space: nowrap;
						padding-bottom: 1em;
						@include mq() {
							height: 7.5em;
						}
						@include mq(lg) {
							height: 6.6em;
						}
						&::after {
							content: "続きを見る >";
							display: inline;
							background: $main-bg-color;
							@include font-size(11);
							width: 100%;
							padding-top: 1.5em;
							text-align: right;
							@include absolute_bottom_left;
							@include mq() {
								@include font-size(13);
							}
							@include mq(lg) {
								padding-top: 0.5em;
							}
						}
						p {
							line-height: 1.5;
							font-size: calc(12px + 1 * (100vw - 320px) / 960);
							width: 100%;
							white-space: normal;
							margin-bottom: 0;
						}
						img {
							display: none;
						}
					}
				}

				&:hover {
					div {
						&:nth-of-type(1) {
							img {
								transform: translate(-50%, -50%) scale(1.2);
							}
						}
					}
				}
			}
			& > a:last-child {
				display: none;
			}
		}
		.l-content {
			position: relative;
			& > .c-more {
				@include absolute_bottom_right($bottom: -100px);
				@include mq(sm) {
					margin: 0;
					@include absolute_top_right($top: 36px);
				}
			}
		}
	}

	//- HOME > ABOUT
	&-about {
		.l-flex {
			&__img {
				background-image: url($imgDir + "home/about.jpg");
			}
			.c-more {
				margin-top: 6em;
			}
		}
		.l-container {
			margin-top: 106px;
		}
	}

	//- HOME > STAY
	&-stay {
		.c-list__yado__photo {
			margin-top: 80px;
		}
	}

	//- HOME > EXPERIENCE
	&-experience {
		.l-flex {
			&__img {
				background-image: url($imgDir + "home/experience.jpg");
			}
			.c-more {
				margin-top: 6em;
			}
		}
		.c-box.border.travel {
			padding: 180px 5vw 100px;
			margin-top: 106px;
			text-align: center;
			@include mq() {
				padding-right: 60px;
				padding-left: 60px;
			}
			*[class^="c-head"] {
				color: $accent-color-travel;
				&::after {
					background-color: $accent-color-travel;
				}
			}
			.c-head__bar {
				&::before {
					content: "";
					display: block;
					width: 50%;
					max-width: 200px;
					height: 0;
					padding-bottom: 28.5%;
					@include bg("common/icon_activity.svg", no, ct, contain);
					@include absolute_top_center($top: -80px);
				}
			}
			& > p.u-goth.u-travel {
				position: relative;
				padding-bottom: 110px;
				&::after {
					content: "";
					display: block;
					width: 40px;
					height: 40px;
					border-top: $accent-color-travel 1px solid;
					border-right: $accent-color-travel 1px solid;
					transform: rotate(135deg);
					@include absolute_bottom_center($bottom: 40px);
				}
			}
			& > div {
				position: relative;
				div {
					width: 100%;
					background: $accent-color-travel;
					padding: 35px;
					margin: 0 auto 15px;
					@include mq(sm) {
						width: 80%;
						max-width: 638px;
					}
					img {
						width: 50%;
						margin: auto;
					}
				}
				&:hover {
					div,
					p.u-goth.u-link {
						opacity: 0.8;
					}
				}
			}
		}
	}

	//- HOME > DINING
	&-dining {
		background: $main-color;
		color: $main-color2;
		.l-flex {
			&__img {
				background-image: url($imgDir + "home/dining.jpg");
			}
			.c-more {
				width: 200px;
				margin-top: 6em;
			}
		}
		.l-container {
			margin-top: 106px;
		}
	}

	//- HOME > SNS
	&-sns {
		.l-container > ul {
			display: flex;
			flex-flow: row wrap;
			margin-top: 20vw;
			margin-right: auto;
			margin-bottom: 20vw;
			margin-left: auto;
			width: 100%;
			max-width: 450px;
			background: rgba($main-color, 0.7);
			@include mq() {
				margin-top: 8vw;
				margin-bottom: 8vw;
				justify-content: space-between;
				max-width: $width-main;
				background: transparent;
			}
			@include mq(xxxl) {
				margin-top: 120px;
				margin-bottom: 120px;
			}
			li {
				width: 50%;
				max-width: 225px;
				height: 0;
				padding-bottom: 50%;
				overflow: hidden;
				position: relative;
				@include mq() {
					width: 19%;
					padding-bottom: 19%;
				}
				&:nth-child(5) {
					@include mq() {
						display: none;
					}
				}
				img {
					width: 100%;
					height: 100%;
					@include centering;
					transition: all 0.6s;
				}
				&:hover {
					img {
						transform: translate(-50%, -50%) scale(1.2);
					}
				}
			}
		}
		&__list {
			width: 100%;
			border-top: $main-color 1px solid;
			padding-top: 80px;
			ul {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				max-width: 742px;
				margin: auto;
				@include mq() {
					width: 80%;
				}
				li {
					width: 25%;
					text-align: center;
					position: relative;
					img {
						width: 26px;
					}
					p {
						font-family: $font-family-en;
						font-weight: $font-weight-bolder;
						letter-spacing: 0.1em;
						margin-top: 1em;
						@include mq_down(xxs) {
							font-size: 10px;
						}
					}
					&:hover {
						img,
						p {
							opacity: 0.6;
						}
					}
				}
			}
		}
	}

	//- HOME > LOCATION
	&-location {
		background-color: $main-bg-color4;
		position: relative;
		padding-bottom: 100%;
		@include bg("home/locationmap_s.svg", no, rb, 100% auto);
		@include mq(lg) {
			padding-bottom: 0;
			background-image: url($imgDir + "home/locationmap.svg");
			background-size: auto 100%;
			background-position: right center;
		}
		@include mq(xl) {
			background-size: auto 150%;
			background-position: right top 62%;
		}
		&__list {
			margin-top: 70px;
			margin-bottom: 50px;
			position: relative;
			z-index: 1;
			@include mq(lg) {
				width: 40%;
			}
			dt {
				font-family: $font-family-en;
				font-weight: $font-weight-bold;
				margin-top: 20px;
				width: 100%;
				span {
					font-family: $font-family-en2;
					font-weight: $font-weight-base;
					padding-left: 1em;
				}
			}
			dd {
				width: 100%;
				padding-left: 2em;
				span {
					display: block;
					letter-spacing: 0.05em;
					&:nth-of-type(1) {
						@include mq(xs) {
							display: inline-block;
						}
					}
					&:nth-of-type(2) {
						@include mq(xs) {
							display: inline-block;
						}
					}
				}
				a {
					width: 110px;
					color: $main-color3 !important;
					font-family: $font-family-en;
					letter-spacing: 0.1em;
					border-bottom: none;
					margin-top: 0.5em;
					&::after {
						border-top-color: $main-color3;
						border-right-color: $main-color3;
						bottom: 1.3em;
					}
				}
			}
		}
	}

	//- HOME > CONTACT
	&-contact {
		.l-content {
			margin: auto;
		}
	}
}

/* ---------------------------------------------
	[EN]Home
--------------------------------------------- */
body.en {
	.p-top {
		&__slider {
			&__header {
				h3 {
					line-height: 1.4 !important;
					margin-bottom: 0.5em;
					span {
						display: inline;
						@include mq_down() {
							line-height: 1.8 !important;
							display: inline-block;
						}
					}
				}
			}
			&__flex__txt {
				@include mq(lg) {
					position: relative;
					height: 100%;
					p {
						padding-bottom: 2em;
					}
					a.c-more {
						@include absolute_bottom_left($left: 6vw);
					}
				}
				@include mq(xl) {
					a.c-more {
						left: 80px;
					}
				}
			}
			&__name {
				&--side {
					transform: translateX(calc(-48vw - 0.5vw)) rotate(-180deg);
					@include mq(xxxl) {
						transform: translateX(-768px) rotate(-180deg);
					}
					p {
						padding-left: 1em;
					}
				}
			}
			.slider-dots-txt {
				padding-right: 0;
				span {
					padding-right: 0;
				}
			}
		}
		&-dining {
			.c-head__sc {
				span {
					color: $main-color2 !important;
				}
			}
		}
		&-sns {
			li {
				p {
					font-size: calc(10px + 4 * (100vw - 320px) / 960);
				}
			}
		}
	}
}
