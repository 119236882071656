@charset "utf-8";
/* ---------------------------------------------
	Heading -- section
--------------------------------------------- */
.c-head__sc {
	line-height: 1.5;
	span {
		&:first-of-type {
			color: $main-color3;
			font-family: $font-family-en;
			font-size: calc(13px + 2 * (100vw - 320px) / 960);
			@include mq_down(xxs) {
				font-size: 4vw;
			}
		}
		&:last-of-type {
			font-family: $font-family-mincho;
			font-size: calc(25px + 5 * (100vw - 320px) / 960);
			@include break;
			@include mq_down(xxs) {
				font-size: 8vw;
			}
		}
	}
	&--rev {
		text-align: center;
		span {
			&:first-of-type {
				font-family: $font-family-mincho;
				font-size: calc(22px + 2 * (100vw - 320px) / 960);
				letter-spacing: -0.1em;
				@include mq_down(xxs) {
					font-size: 6.4vw;
				}
			}
			&:last-of-type {
				color: $main-color3;
				font-family: $font-family-en;
				font-size: calc(13px + 2 * (100vw - 320px) / 960);
				@include break;
				@include mq_down(xxs) {
					font-size: 4vw;
				}
			}
		}
		&.border {
			margin-bottom: 178px;
			position: relative;
			&::after {
				content: "";
				display: block;
				width: 1px;
				height: 74px;
				background: $main-color3;
				@include absolute_bottom_center($bottom: -115px);
			}
		}
	}
}

/* ---------------------------------------------
	[EN]Heading -- section
--------------------------------------------- */
body.en {
	.c-head__sc {
		span {
			&:first-of-type {
				color: $main-color;
				font-family: $font-family-mincho;
				font-size: calc(25px + 5 * (100vw - 320px) / 960);
				@include mq_down(xxs) {
					font-size: 8vw;
				}
			}
			&:last-of-type {
				&::before {
					display: none;
				}
			}
		}
		&--rev {
			span {
				color: $main-color !important;
				font-family: $font-family-mincho !important;
				font-size: calc(22px + 2 * (100vw - 320px) / 960) !important;
				letter-spacing: 0.1em;
				@include mq_down(xxs) {
					font-size: 6.4vw !important;
				}
			}
		}
	}
}

/* ---------------------------------------------
	Heading -- xx large
--------------------------------------------- */
.c-head__xxl {
	font-size: calc(32px + 8 * (100vw - 320px) / 960);
	letter-spacing: 0.1em;
	margin-bottom: 1.6em;
	@include mq_down(xs) {
		letter-spacing: 0;
	}
	@include mq_down(xxs) {
		font-size: 10vw;
	}
}

/* ---------------------------------------------
	[EN]Heading -- xx large
--------------------------------------------- */
.c-head__xxl {
	font-size: calc(25px + 6 * (100vw - 320px) / 960);
	letter-spacing: 0.05em;
	@include mq_down(xxs) {
		font-size: 8vw;
	}
	span {
		display: inline-block;
	}
}

/* ---------------------------------------------
	Heading -- x large
--------------------------------------------- */
.c-head__xl {
	font-size: calc(27px + 5 * (100vw - 320px) / 960);
	letter-spacing: -0.1em;
	@include mq_down(xs) {
		letter-spacing: 0;
	}
	@include mq_down(xxs) {
		font-size: 8.5vw;
	}
}

/* ---------------------------------------------
	Heading -- large
--------------------------------------------- */
.c-head__l {
	font-size: calc(25px + 5 * (100vw - 320px) / 960);
	letter-spacing: -0.1em;
	@include mq_down(xs) {
		letter-spacing: 0;
	}
	@include mq_down(xxs) {
		font-size: 8vw;
	}
}

/* ---------------------------------------------
	Heading -- middle 24
--------------------------------------------- */
.c-head__m {
	font-size: calc(22px + 2 * (100vw - 320px) / 960);
	letter-spacing: 0.1em;
	margin-bottom: 1.5em;
	@include mq_down(xs) {
		letter-spacing: 0;
	}
	@include mq_down(xxs) {
		font-size: 6.4vw;
	}
	span {
		display: inline-block;
	}
	&.gry {
		color: $main-color3;
	}
}

/* ---------------------------------------------
	[EN]Heading -- middle 24
--------------------------------------------- */
body.en {
	.c-head__m {
		font-size: calc(21px + 3 * (100vw - 320px) / 960);
		line-height: 1.8;
	}
}

/* ---------------------------------------------
	Heading -- small 18
--------------------------------------------- */
.c-head__s {
	font-size: calc(16px + 2 * (100vw - 320px) / 960);
	letter-spacing: 0.1em;
	@include mq_down(xxs) {
		font-size: 4.8vw;
	}
	&.gry {
		color: $main-color3;
	}
}

/* ---------------------------------------------
	Heading -- small 16
--------------------------------------------- */
.c-head__ss {
	font-size: calc(15px + 1 * (100vw - 320px) / 960);
	font-weight: $font-weight-bolder;
	letter-spacing: 0.1em;
	margin-bottom: 0.5em;
	@include mq_down(xxs) {
		font-size: 4.5vw;
	}
}

/* ---------------------------------------------
	Heading -- bar
--------------------------------------------- */
.c-head__bar {
	text-align: center;
	position: relative;
	padding-bottom: 5px;
	margin-bottom: 2em;
	&::after {
		display: block;
		content: "";
		width: 30px;
		height: 3px;
		@include absolute_bottom_center;
	}
}

/* ---------------------------------------------
	[EN]Heading -- bar
--------------------------------------------- */
body.en {
	.c-head__bar {
		font-family: $font-family-en;
	}
}
