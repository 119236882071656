@charset "utf-8";

/* ---------------------------------------------
	Key visual
--------------------------------------------- */
.c-keyvisual {
	width: 100vw;
	height: 57.5vw;
	@include bg("n", no, left 30% top, auto 100%);
	position: relative;

	@include mq(xs) {
		height: 45vw;
	}

	@include mq(xxxl) {
		height: 35vw;
	}

	#about & {
		background-image: url($imgDir + "keyvisual/about.jpg");
	}

	#stay & {
		background-image: url($imgDir + "keyvisual/stay.jpg");
	}

	#experience & {
		background-image: url($imgDir + "keyvisual/experience.jpg");
	}

	#contact & {
		background-image: url($imgDir + "keyvisual/contact.jpg");
	}

	#privacy & {
		background-image: url($imgDir + "keyvisual/privacy.jpg");
	}

	#sitemap & {
		background-image: url($imgDir + "keyvisual/sitemap.jpg");
	}
}
