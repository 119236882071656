@charset "utf-8";

/* ---------------------------------------------
	Main
--------------------------------------------- */
.l-wrapper {
	overflow: hidden;
	position: relative;
}

main {
	min-height: 600px;
	margin-top: calc(70px + 86 * (100vw - 320px) / 960);

	@include mq(lg) {
		margin-top: 200px;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: $font-family-mincho;
		font-weight: $font-weight-semibold;
	}

	p,
	ul,
	ol,
	dl {
		font-family: $font-family-mincho;
		font-size: calc(13px + 1 * (100vw - 320px) / 960);
		letter-spacing: -0.02em;
		line-height: 2;
		@include mq_down(xxs) {
			font-size: 4vw;
		}
	}

	p {
		margin-bottom: 2em;
		span {
			display: inline-block;
		}
	}
}

section,
aside {
	position: relative;
	padding-top: vwsp(100);
	padding-bottom: vwsp(100);
	z-index: 0;

	@include mq(xs) {
		padding-top: 97px;
		padding-bottom: 97px;
	}
	@include mq(lg) {
		padding-top: 150px;
		padding-bottom: 150px;
	}
}

section {
	&.blk {
		background-color: $main-color;
		& .c-head__sc span:nth-of-type(2),
		p {
			color: $main-color2;
		}
	}
	&.lgy {
		background-color: $main-bg-color3;
	}
	&.gry {
		background-color: $main-bg-color4;
	}
	&.mt {
		background-color: $main-bg-color3;
		padding-top: 23vw;
		z-index: 0;
		@include mq_down() {
			padding-top: 27vw;
		}
		@include mq(xxxl) {
			padding-top: 15vw;
		}
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: 23vw;
			@include bg("common/silhouette_mountain.svg", no, cb, 100% auto);
			background-color: $main-bg-color;
			@include absolute_top_center;
			@include mq(xxxl) {
				height: 168px;
				padding-top: 0;
			}
		}
	}
}

.l-container {
	width: calc(100% - 12vw);
	max-width: $width-main;
	background-color: transparent;
	margin: auto;
	position: relative;
	.l-content {
		width: 100%;
		max-width: 1120px;
		margin: auto;
	}
}

.l-flex {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 1;
	@include mq(lg) {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}
	@include mq(xxxl) {
		justify-content: center;
	}
	&__txt {
		width: calc(100% - 12vw);
		margin: auto;
		padding-bottom: 60px;
		@include mq(lg) {
			width: 40%;
			padding-left: 6vw;
			padding-right: 5vw;
			padding-bottom: 0;
		}
		@include mq(xxxl) {
			padding-left: calc(((100vw - 1280px) / 2) - 160px);
			padding-right: 0px;
		}
		& > .c-head__sc {
			margin-top: -1em;
			margin-bottom: 10vw;
		}
	}
	&__img {
		width: 100%;
		height: 0;
		padding-bottom: 60%;
		@include bg("n", no, cc, cover);

		@include mq(lg) {
			width: 60%;
			height: auto;
			padding-bottom: 0;
			background-position: left center;
		}
		@include mq(xxxl) {
			width: 50%;
		}
	}
	&.reverse {
		@include mq(lg) {
			flex-direction: row-reverse;
		}
		.l-flex__txt {
			@include mq(lg) {
				padding-left: 5vw;
				padding-right: 6vw;
			}
			@include mq(xxxl) {
				padding-left: 0px;
				padding-right: calc(((100vw - 1280px) / 2) - 160px);
			}
		}
		.l-flex__img {
			@include mq(lg) {
				background-position: right center;
			}
		}
	}
}

/* ---------------------------------------------
	[EN]Main
--------------------------------------------- */
body.en {
	main {
		p,
		ul,
		ol,
		dl {
			overflow-wrap: break-word;
			letter-spacing: 0;
			@include mq_down(xxs) {
				font-size: 5vw;
			}
		}
		span {
			padding-right: 0.5em;
		}
		.l-container,
		.l-flex__txt {
			p {
				font-size: calc(15px + 1 * (100vw - 320px) / 960);
				@include mq_down(xxs) {
					font-size: 4vw;
				}
			}
		}

		.l-flex {
			&__txt {
				& > .c-head__sc {
					margin-top: -0.5em;
					margin-bottom: 6vw;
				}
			}
		}
	}
}
