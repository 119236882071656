@charset "utf-8";

/* ---------------------------------------------
	Form -- for mfp
--------------------------------------------- */
div#mfp_hidden {
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0px;
	margin: 0px;
}

table#mfp_confirm_table {
	border-spacing: 0px;
	border-collapse: collapse;
	width: 100%;
	margin: 20px 0 40px;
	border-bottom: 1px solid $inputbrdr;

	tr.mfp_colored {
		background-color: rgba($accent-color, 0.1);
	}

	tr.mfp_achroma {
		background-color: $achroma;
	}

	tr th {
		text-align: left;
		@include font-size(12);
		border-top: solid 1px $inputbrdr;
		padding: 10px;
		white-space: nowrap;
		width: 200px;

		@include mq_down(md) {
			white-space: nowrap;
			width: 100px;
		}
	}

	tr td {
		text-align: left;
		@include font-size(12);
		border-top: solid 1px $inputbrdr;
		padding: 10px;
		line-height: 1.5em;
		word-break: break-all;
	}
}

div#mfp_phase_confirm {
	clear: both;

	h4 {
		font-size: 150%;
		padding: 0;
		text-align: center;
		background: $achroma;

		&::before {
			width: 100%;
			height: 50px;
			content: "";
			display: block;
			background: $achroma;
			clear: both;
			margin-top: -40px;
			position: relative;
			z-index: 100;
		}
	}
}

div#mfp_overlay {
	position: absolute;
	display: none;
	height: 100%;
	color: $blk;
	z-index: 10001;

	&_inner {
		width: 640px;
		max-width: 90%;
		padding: 15px;
		background-color: $achroma;
		border-radius: 5px;
		box-shadow: 0px 0px 10px $blk;
		position: absolute;
		top: 100px;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;

		h4 {
			text-align: center;
		}
	}

	&_background {
		background-color: $blk;
		position: absolute;
		display: none;
		z-index: 10000;
	}
}

div#mfp_loading {
	z-index: 20001;
	position: absolute;
	display: none;
	width: 40px;
	height: 40px;
	background-image: url(../cgi/mfp.statics/_images/mfp_loading.gif);

	&_screen {
		z-index: 20000;
		opacity: 0.8;
		display: none;
		background-color: $blk;
		position: absolute;
	}
}

.mfp_colored {
	background-color: $colored;
}

.mfp_achroma {
	background-color: $achroma;
}

div.mfp_err {
	clear: both;
	display: none;
	text-align: left;
	margin: 5px 0px 0px 0px;
	padding: 3px 0px 5px 22px;
	color: $err;
	@include font-size(12);
	font-weight: $font-weight-bold;
	line-height: normal;
	background: url(../cgi/mfp.statics/_images/mfp_error.gif) no-repeat 0 center/16px 15px;
}

.mfp_parent_error {
	border: solid 2px $must;
}

.problem {
	background-color: rgba($must, 0.1);
}

div#mfp_error {
	background-color: rgba($must, 0.1);
	border: solid 1px $must;
	padding: 10px;
	display: none;

	p {
		padding: 0px;
		margin: 0px;
		@include font-size(14);
		text-align: center;

		strong {
			@include font-size(18);
			color: $err;
		}
	}
}

div#mfp_warning {
	background-color: $wrn;
	border: solid 1px $must;
	padding: 10px;
	display: none;
	border-radius: 5px;

	p {
		padding: 0px;
		margin: 0px;
		@include font-size(14);
		text-align: center;

		strong {
			@include font-size(18);
			color: $must;
		}
	}
}

button.mfp_prev {
	@include font-size(14);
	margin: 10px;
	padding: 5px 10px;
	float: left;
}

button.mfp_next {
	@include font-size(14);
	margin: 10px;
	padding: 5px 10px;
	float: right;
}

div.mfp_buttons {
	clear: both;
	padding: 10px;
	text-align: center;

	#mfp_button_send {
		width: 8em;
		background: $submit1;
		color: $submit_txt;
		border: 1px solid $submit1;
	}

	#mfp_button_cancel {
		width: 8em;
		background: $reset1;
		color: $reset2;
		border: 1px solid $submit1;
	}
}

.imagebutton {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
	background: none;
}

.mfp_element_file {
	@include font-size(12);
	display: inline-block;
	padding: 10px 10px;
	vertical-align: middle;
	border: solid 1px $inputbrdr;
	border-radius: 3px;
	box-shadow: 0px 0px 5px $inputbrdr inset;
	background: $colored;
}

div.mfp_ok {
	border-radius: 5px;
	background-color: $ok;
	border: solid 1px $ok;
	line-height: 1.5em;
	padding: 0px 5px;
	margin: 0px 2px;
	color: $achroma;
	@include font-size(10);
	background-image: url(../cgi/mfp.statics/_images/mfp_header.png);
	background-size: 100% 100%;
	display: none;
}

div.prefcodeWrapper {
	position: relative;
}

div.prefcodeResult {
	position: absolute;
	top: 0px;
	left: 0px;
	padding: 5px;
	border: solid 1px $inputbrdr;
	background-color: $achroma;
	box-shadow: 0px 0px 5px $inputbrdr;
	display: none;

	div {
		cursor: pointer;

		&:hover {
			background-color: rgba($accent-color, 0.5);
		}

		.prefcodeNext {
			background: $achroma;
			text-align: center;
		}
	}
}

div.prefLoading {
	padding: 60px 100px;
	background: url(../cgi/mfp.statics/_images/mfp_zip_loading.gif) no-repeat center center;
}

.hidefield {
	height: 0px;
	overflow: hidden;
}

.showfield {
	height: auto;
	overflow: visible;
}

div#mfp_OperationCheck {
	display: inline-block;

	div#mfp_OperationCheck_inner {
		display: none;
	}

	& strong {
		background: rgba($must, 0.3);
		display: block;
		color: $main-color;
		text-align: center;
		border-radius: 3px;
	}

	& * {
		padding: 0px;
		margin: 0px;
		@include font-size(12);
	}

	p {
		padding: 0px 5px;
		margin: 0;
		@include font-size(12);

		a {
			color: $ok;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		button {
			border: none;
			background: none;
			color: $ok;
			cursor: pointer;
			text-align: center;
		}
	}
}

div#mfp_OperationCheck ul {
	li {
		line-height: 12px;
		@include font-size(12);
		list-style: none;
		vertical-align: middle;
		padding: 5px 10px;

		label {
			text-decoration: underline;
		}
	}
}

/* ---------------------------------------------
	Contact
--------------------------------------------- */
.c-contact {
	font-family: $font-family-base;
	margin: auto;
	dt {
		margin-top: 100px;
		margin-bottom: 0.5em;
		span.u-must {
			display: block;
			@include mq(sm) {
				float: right;
			}
		}
	}
	dd {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		background: $main-color;
		color: $main-color2;
		font-family: $font-family-en;
		font-weight: $font-weight-bold;
		@include font-size(18);
		letter-spacing: 0;
		padding: 0.5em 15px;
		@include mq_down(xxs) {
			display: block;
			font-size: 4.5vw;
			line-height: 1.6;
			padding: 1em;
			text-align: left;
		}
		@include mq(xs) {
			@include font-size(20);
		}
		span.r {
			display: block;
			font-family: $font-family-mincho;
			font-weight: $font-weight-bold;
			@include font-size(12);
			@media all and (max-width: 369px) {
				width: 100%;
				text-align: right;
			}
			@include mq_down(xxs) {
				font-size: 3.5vw;
			}
			@include mq(sm) {
				@include font-size(13);
			}
		}
		&:nth-of-type(1) {
			a {
				color: $main-color2;
				@include font-size(20);
				font-family: $font-family-en;
				font-weight: $font-weight-bold;
				@include mq_down(xxs) {
					display: block;
					width: 100%;
					font-size: 5.2vw;
					text-align: left;
				}
				@include mq(xs) {
					@include font-size(23);
				}
			}
		}
	}
}
/* ---------------------------------------------
	Form
--------------------------------------------- */
.c-form {
	width: 100%;
	margin: auto;
	@include mq() {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	li {
		width: 100%;
		margin-top: 53px;
		&.half {
			@include mq() {
				width: 47.5%;
			}
		}
		p {
			font-family: $font-family-base;
			@include font-size(14);
			line-height: 1;
			margin-bottom: 12px;
			@include mq_down(xxs) {
				font-size: 4vw;
			}
		}
	}

	input,
	textarea {
		width: 100%;
		padding: 0.5em 1em;
		border-radius: 0;
		border: $inputbrdr 1px solid;
		outline: none;
		@include font-size(16);
		background: $inputbk;
		-webkit-appearance: none;
		transition: all 0.3s;
		&:focus {
			background: $achroma;
			box-shadow: 0 0 3px $box-shadow !important;
			border: 1px solid $inputbrdr;
		}
	}

	input {
		height: 3em;
	}

	textarea {
		width: 100% !important;
		height: calc(8em + 20px) !important;
		margin-left: 0;
		margin-bottom: 20px;
		line-height: 1.6;
	}

	::placeholder {
		color: $placeholder;
	}

	.u-must {
		color: $must;
		font-size: 80%;
		margin-left: 0.5em;
	}

	p.must {
		text-align: right;
	}
	&__btnarea {
		button[type="submit"] {
			display: block;
			width: 100%;
			max-width: 320px;
			padding: 5px 0;
			border-radius: 0;
			margin: 80px auto 0;
			background: $submit1;
			color: $main-color2;
			border: 1px solid $submit1;
			@include font-size(15);
			cursor: pointer;
			transition: 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);

			&:hover {
				background: $submit2;
				color: $main-color;
				font-weight: $font-weight-bold;
			}
		}
	}
}
