@charset "utf-8";

/* ---------------------------------------------
	Header
--------------------------------------------- */
.l-header {
	width: 100%;
	height: calc(70px + 86 * (100vw - 320px) / 960);
	z-index: 11;
	background: rgba($main-bg-color, 0.9);
	position: fixed;
	top: 0;
	z-index: 1;
	transition: all 1s;

	@include mq(lg) {
		height: 200px;
	}

	&__inner {
		width: calc(100% - 6vw);
		max-width: $width-base;
		height: 100%;
		position: relative;
		left: 50%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		z-index: 11;
		transform: translateX(-50%);
		transition: cubic-bezier(1, 0, 0, 1);
	}

	&__logo {
		width: 60vw;
		max-width: 320px;
		height: auto;
		max-height: 71px;
		line-height: 0;
		top: 50%;
		left: 0;
		margin-top: -0.5vw;
		position: relative;
		z-index: 10;
		transform: translateY(-50%);
		@include mq_down(lg) {
			left: 10px;
		}
		@include mq_down(xs) {
			max-width: 216px;
			max-height: 48px;
		}

		@include mq(lg) {
			width: 33vw;
			height: 80px;
			max-height: 80px;
			margin-top: 10px;
		}
		@include mq(xl) {
			width: 320px;
		}

		a {
			@include over;
		}
		.open & {
			z-index: 0;
			a {
				img {
					opacity: 0;
				}
			}
		}
	}
	&.fixed {
		height: 70px;
		border-bottom: 1px solid rgba($main-color3, 0.2);
		.l-header__logo {
			max-width: 175px;
			max-height: 39px;
			margin-top: 0;
			a {
				img {
					max-width: 175px;
					max-height: 39px;
				}
			}
		}
		.c-nav__header__sub {
			display: none;
		}
		.c-nav__header__main {
			top: 15%;
		}
	}
	&.open {
		height: 80px;
		border-bottom: none;
		.l-header__inner {
			.l-header__logo,
			.c-nav__header__sub,
			.c-nav__header__main {
				opacity: 0;
			}
			.l-header__logo a,
			.c-nav__header__sub a,
			.c-nav__header__main a {
				pointer-events: none;
			}
		}
	}
}
