@charset "utf-8";

/* ---------------------------------------------
	Area -- link
--------------------------------------------- */
.c-area__link {
	background: $main-bg-color4;
	ul {
		display: flex;
		flex-flow: column wrap;
		margin-top: 40px;
		margin-right: auto;
		margin-left: auto;
		width: 100%;
		max-width: 600px;

		@include mq(sm) {
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 100%;
		}
		li {
			width: 100%;
			margin-bottom: 10vw;
			position: relative;
			transition: all 0.4s ease-in-out;
			@include mq(lg) {
				margin-bottom: 116px;
			}
			&:hover {
				opacity: 0.9;
			}
			.c-area__link__ttl {
				font-size: calc(13px + 1 * (100vw - 320px) / 680);
				margin-top: 0.5em;
				@include mq_down(xxs) {
					font-size: 4vw;
				}
				&::after {
					content: "";
					display: inline-block;
					width: 1em;
					height: 1em;
					@include bg("common/icon_link.svg", no, center top 2px, contain);
					margin-left: 8px;
					padding-bottom: 3px;
				}
			}
			&:nth-child(1),
			&:nth-child(2) {
				max-width: 600px;
				@include mq(sm) {
					width: 48%;
				}
				& > div {
					width: 100%;
					height: 0;
					padding-bottom: 26.66%;
					position: relative;
					img {
						width: auto;
						@include centering;
					}
				}
			}
			&:nth-child(1) {
				& > div {
					background: $accent-color-travel;
					img {
						height: 34%;
					}
				}
			}
			&:nth-child(2) {
				& > div {
					background: $accent-color-shop;
					img {
						height: 48%;
					}
				}
			}
			&:nth-child(3) {
				margin-bottom: -1em;
				& > div {
					background: $accent-color-bbb;
					padding: 8vw 3vw;
					@include mq(sm) {
						width: 100%;
					}
					@include mq(lg) {
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;
						padding: 60px 70px 90px 60px;
					}
					& > p {
						width: 25vw;
						max-width: 164px;
						margin-right: auto;
						margin-left: auto;
						@include mq() {
							width: 15%;
							@include absolute_top_right($top: 6vw, $right: 7%);
						}
						@include mq(lg) {
							width: 168px;
							max-width: 168px;
							height: 190px;
							top: 47%;
							transform: translateY(-50%);
							img {
								width: 100%;
								height: auto;
							}
						}
					}
					.c-area__link__txt {
						width: 86%;
						color: $main-color2;
						margin-right: auto;
						margin-bottom: 3em;
						margin-left: auto;
						@include mq() {
							width: 92%;
							margin-bottom: -2em;
						}
						@include mq(lg) {
							width: calc(100% - 200px);
							margin-left: 0;
						}
						h3 {
							font-size: calc(21px + 19 * (100vw - 320px) / 690);
							letter-spacing: 0.2em;
							margin-bottom: 0.5em;
							@include mq(lg) {
								@include font-size(40);
								letter-spacing: 0.3em;
							}
						}
						p {
							font-size: calc(13px + 1 * (100vw - 320px) / 690);
							letter-spacing: -0.01em;
							@include mq_down(xxs) {
								font-size: 4vw;
							}
						}
					}
				}
			}
		}
	}
}

/* ---------------------------------------------
	[EN]Area -- link
--------------------------------------------- */
body.en {
	.c-area__link {
		.c-area__link__txt {
			h3 {
				font-size: calc(21px + 15 * (100vw - 320px) / 690);
				letter-spacing: 0;
				line-height: 1.5;
				padding-bottom: 1em;
				span {
					display: block;
				}
				@include mq(lg) {
					@include font-size(30);
					letter-spacing: 0.1em !important;
					span {
						display: inline-block;
						padding-right: 0.5em;
					}
				}
			}
			p {
				letter-spacing: 0.1em !important;
			}
		}
	}
}
