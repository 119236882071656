@charset "utf-8";

/* ---------------------------------------------
	List -- disc
--------------------------------------------- */
.c-list__disc {
	list-style: none;
	margin-left: 1.2em;
	text-indent: -1em;
	margin-top: -1.6em;
	li {
		&::before {
			content: "・";
			width: 0.5em;
			margin-right: 0.3em;
		}
	}
}

/* ---------------------------------------------
	List -- sns
--------------------------------------------- */
.c-list__sns {
	display: flex;
	flex-flow: row wrap;
	width: 180px;
	a {
		height: 20px;
		line-height: 1;
	}
}

/* ---------------------------------------------
	List -- yado
--------------------------------------------- */
.c-list__yado {
	display: flex;
	flex-flow: column wrap;
	@include mq(sm) {
		flex-flow: row wrap;
		justify-content: space-between;
	}
	li {
		width: 100%;
		max-width: 238px;
		height: 58px;
		color: $main-color2;
		line-height: 1.4;
		padding-top: 10px;
		border: $main-color2 1px solid;
		margin-bottom: 30px;
		text-align: center;
		position: relative;
		@include mq(sm) {
			width: 31%;
		}
		span {
			display: block;
			&:nth-of-type(1) {
				font-family: $font-family-mincho;
			}
			&:nth-of-type(2) {
				color: $main-color3;
				font-family: $font-family-en;
				@include font-size(12);
				letter-spacing: 0.1em;
			}
		}
	}
}

/* ---------------------------------------------
	[EN]List -- yado
--------------------------------------------- */
body.en {
	.c-list__yado {
		li {
			span {
				@include mq(sm) {
					&:nth-of-type(1) {
						font-size: 82%;
					}
					&:nth-of-type(2) {
						@include font-size(11);
					}
				}
			}
		}
	}
}

/* ---------------------------------------------
	List -- yado with photo
--------------------------------------------- */
.c-list__yado__photo {
	width: 100%;
	max-width: $width-main;
	margin: auto;
	@include mq() {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-bottom: 0;
	}
	&__item {
		width: 100%;
		max-width: 480px;
		height: 0;
		padding-bottom: 106%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-bottom: 16vw;
		margin-right: auto;
		margin-left: auto;
		@include mq(sm) {
			margin-bottom: 60px;
		}
		@include mq() {
			width: 40vw;
			max-width: 600px;
			padding-bottom: 42.5vw;
			margin-bottom: 12vw;
			margin-left: 0;
			margin-right: 0;
		}
		@include mq(xxl) {
			width: 600px;
			height: 640px;
			padding-bottom: 0;
			margin-bottom: 160px;
		}
		h3 {
			display: flex;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			align-items: center;
			width: 13%;
			padding-top: 1.5em;
			border-top: $main-color 1px solid;
			font-size: calc(18px + 12 * (100vw - 320px) / 1280);
			@include mq(xxl) {
				@include font-size(30);
			}
			&.notxt {
				border-top: none;
			}
		}
		.c-box {
			width: 80%;
			padding: 4vw 4vw 8vw;
			position: relative;
			@include mq() {
				padding: 2vw;
			}
			@include mq(lg) {
				padding: 20px;
			}
			& > div {
				width: 100%;
				height: 0;
				padding-bottom: 91%;
				overflow: hidden;
				transition: 1s all;
				img {
					width: 100%;
					margin: auto;
				}
			}
			& > img {
				margin: auto;
				width: 30%;
				margin-top: 30px;
			}
			&:hover {
				& > div {
					img {
						transform: scale(1.2, 1.2);
						transition: 1s all;
					}
				}
				& > img {
					opacity: 0;
				}
			}
			a.u-over {
				background: $main-color;
				color: $main-color2;
				padding: 8vw 7vw 10vw 8vw;
				opacity: 0;
				z-index: 0;
				@include mq(sm) {
					padding: 4vw 3.5vw 5vw 4vw;
				}
				@include mq() {
					padding: 3vw 2vw 5vw 3vw;
				}
				@include mq(lg) {
					padding: 40px 30px 100px 40px;
				}
				@include mq(xl) {
					padding: 60px 30px 100px 40px;
				}
				&:hover,
				&.js-hover:hover {
					opacity: 0.9;
					img {
						opacity: 1;
					}
				}
				h4 {
					font-size: calc(15px + 5 * (100vw - 320px) / 457);
					margin-bottom: 6vw;
					line-height: 1.6;
					text-align: center;
					@include mq() {
						font-size: calc(15px + 6 * (100vw - 767px) / 833);
						margin-bottom: 2vw;
					}
					@include mq(xxxl) {
						@include font-size(21);
					}
				}
				p {
					@include font-size(11);
					line-height: 1.6;
					@include mq(xs) {
						@include font-size(13);
					}
					@include mq(sm) {
						@include font-size(14);
					}
					@include mq() {
						font-size: calc(11px + 4 * (100vw - 767px) / 513);
					}
					@include mq(xxxl) {
						@include font-size(16);
					}
					&.clamp {
						height: 12.5em;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 14;
						overflow: hidden;
						word-wrap: break-word;
						position: relative;
						z-index: 0;
						&::before {
							bottom: -0.25em;
							content: "・・・";
							color: $main-color2;
							font-family: Meiryo;
							background: $main-color;
							padding-left: 0.5em;
							position: absolute;
							right: 0;
						}
						@include mq(xl) {
							height: 20em;
							&::before {
								display: none;
							}
						}
					}
				}
				img {
					display: block;
					opacity: 0;
					width: calc((100% - 8vw) * 0.3);
					@include absolute_bottom_center($bottom: 8vw);
					z-index: 3;
					@include mq() {
						width: calc((100% - 4vw) * 0.3);
						bottom: 2vw;
					}
					@include mq(lg) {
						width: calc((100% - 40px) * 0.3);
						bottom: 20px;
					}
					@include mq(xxl) {
						bottom: 40px;
					}
				}
			}
		}
		&.pre {
			.c-box {
				&:hover {
					div > img {
						display: none;
					}
					a.u-over {
						background: $main-color url($imgDir + "home/stay_2023open_on.png") no-repeat center top/contain;
					}
				}
			}
		}
		&.comingsoon {
			.c-box {
				background: $main-bg-color3 url($imgDir + "home/stay_comingsoon.svg") no-repeat center/40% auto;
				&:hover {
					img {
						opacity: 0;
					}
					a.u-over {
						background: transparent;
						opacity: 0;
					}
				}
			}
		}
	}
}

#stay .c-list__yado__photo {
	&__item {
		&.pre,
		&.comingsoon {
			.c-box {
				background-color: $main-bg-color4;
			}
		}
	}
}

/* ---------------------------------------------
	[EN]List -- yado with photo
--------------------------------------------- */
body.en {
	.c-list__yado__photo {
		&__item {
			h3 {
				font-size: calc(15px + 9 * (100vw - 320px) / 447);
				line-height: 1.2;
				padding-top: 1em;
				text-align: left;
				@include mq_down(xxs) {
					font-size: 4.5vw;
				}
				@include mq() {
					font-size: calc(15px + 12 * (100vw - 767px) / 713);
				}
				@include mq(xxl) {
					@include font-size(27);
				}
			}
			.c-box {
				a.u-over {
					padding-top: 6vw;
					@include mq() {
						padding-top: 4vw;
						padding-right: 3vw;
						padding-left: 3.5vw;
					}
					@include mq(lg) {
						padding: 50px 30px 100px 40px;
					}
					h4 {
						font-size: calc(11px + 4 * (100vw - 320px) / 457);
						line-height: 1.5;
						margin-bottom: 3vw;
						@include mq_down(xxs) {
							font-size: 3vw;
						}
						@include mq(sm) {
							@include font-size(15);
						}
						@include mq() {
							font-size: calc(11px + 11 * (100vw - 767px) / 833);
							margin-bottom: 1vw;
						}
						@include mq(xxxl) {
							@include font-size(22);
						}
					}
					p {
						text-align: justify;
						text-justify: inter-ideograph;
						@include font-size(10);
						line-height: 1.6;
						@include mq_down(xxs) {
							font-size: 2.5vw;
						}
						@include mq(sm) {
							@include font-size(13);
						}
						@include mq() {
							font-size: calc(10px + 6 * (100vw - 767px) / 833);
						}
						@include mq(xxxl) {
							@include font-size(16);
						}
						&.clamp {
							height: 16em;
							@include mq(xl) {
								height: 24em;
							}
						}
					}
				}
			}
		}
	}
}

/* ---------------------------------------------
	List -- group all
--------------------------------------------- */
.c-list__group__all {
	width: calc(100% - 12vw);
	max-width: 318px;
	margin: 80px auto;
	@include mq() {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 100%;
		max-width: 637px;
	}
	@include mq(lg) {
		max-width: $width-main;
	}
	li {
		width: calc(100% - 2px);
		max-width: 318px;
		height: 160px;
		margin-top: 80px;
		margin-right: auto;
		margin-left: auto;
		border-left: $main-color 1px solid;
		border-right: $main-color 1px solid;
		text-align: center;
		position: relative;
		transition: all 2s linear;
		@include mq(lg) {
			height: 0;
			padding-bottom: 15%;
		}
		p {
			&:first-of-type {
				img {
					&:first-of-type {
						display: inline;
					}
					&:last-of-type {
						display: none;
					}
				}
			}
			&:last-of-type {
				font-family: $font-family-base;
				font-size: calc(13px + 1 * (100vw - 320px) / 680);
				@include absolute_bottom_center;
				&.ext {
					&::after {
						content: "";
						display: inline-block;
						width: 1em;
						height: 1em;
						@include bg("common/icon_link.svg", no, cb, auto 87%);
						margin-left: 8px;
					}
				}
			}
		}
		&.main {
			@include mq() {
				max-width: 319px;
				border-right: none;
			}
			@include mq(lg) {
				width: 25%;
				max-width: 25%;
			}
			img {
				width: 30%;
				max-width: 90px;
			}
		}
		&.rera {
			@include mq(lg) {
				width: 25%;
				max-width: 25%;
				border-right: none;
			}
			img {
				width: 30%;
				max-width: 90px;
			}
		}
		&.fure {
			@include mq() {
				max-width: 319px;
				border-right: none;
			}
			@include mq(lg) {
				width: 25%;
				max-width: 25%;
				border-right: none;
			}
			img {
				width: 30%;
				max-width: 90px;
			}
		}
		&.ipe {
			@include mq(lg) {
				width: 25%;
				max-width: 25%;
			}
			img {
				width: 80%;
				max-width: 293px;
				margin-top: 35px;
				@include mq() {
					width: 67%;
					max-width: 214px;
				}
			}
		}
		&.bbb {
			@include mq() {
				max-width: 427px;
			}
			@include mq(lg) {
				width: 33.3%;
				max-width: 33.3%;
				border-right: none;
			}
			img {
				width: 56%;
				max-width: 180px;
				margin-top: 10px;
			}
		}
		&.travel {
			@include mq() {
				max-width: 427px;
			}
			@include mq(lg) {
				width: 33.3%;
				max-width: 33.3%;
				border-right: none;
			}
			img {
				width: 76%;
				max-width: 246px;
				margin-top: 35px;
			}
		}
		&.shop {
			@include mq_down(lg) {
				margin-bottom: 100px;
			}
			@include mq() {
				max-width: 427px;
			}
			@include mq(lg) {
				width: 33.3%;
				max-width: 33.3%;
			}
			img {
				width: 80%;
				max-width: 293px;
				margin-top: 35px;
			}
		}
		&:hover {
			p:first-of-type {
				img {
					&:first-of-type {
						display: none;
					}
					&:last-of-type {
						display: inline;
					}
				}
			}
		}
		&.pre {
			a.u-over {
				background: rgba($main-bg-color3, 0.8);
				&::before {
					content: "2023　NEW OPEN";
					display: block;
					font-family: $font-family-base;
					@include font-size(16);
					padding-top: 20%;
					text-align: center;
				}
				&:hover {
					opacity: 1;
				}
				& ~ p {
					&:first-of-type {
						img {
							display: inline;
						}
					}
				}
			}
		}
	}
}

/* ---------------------------------------------
	List -- photo
--------------------------------------------- */
.c-list__photo {
	width: 100%;
	max-width: 350px;
	margin: 30vw auto 20vw;
	margin-right: auto;
	margin-left: auto;
	@include mq(sm) {
		max-width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 80px;
		margin-bottom: 40px;
	}
	li {
		position: relative;
		@include mq(sm) {
			width: 48%;
		}
		@include mq(lg) {
			width: 23%;
			margin-bottom: 0;
		}
		p {
			line-height: 1.2;
			text-align: left;
			margin-top: 1.2em;
			span {
				display: block;
				&:first-of-type {
					color: $accent-color-travel;
					font-family: $font-family-en;
					font-size: calc(12px + 2 * (100vw - 320px) / 960);
					font-weight: 700;
					letter-spacing: 0.05em;
					@include mq_down(xxs) {
						font-size: 3vw;
					}
				}
				&:last-of-type {
					color: $main-color;
					font-family: $font-family-mincho;
					font-size: calc(15px + 3 * (100vw - 320px) / 960);
					letter-spacing: 0.15em;
					@include mq_down(xxs) {
						font-size: 6vw;
					}
				}
			}
		}
	}
}

/* ---------------------------------------------
	[EN]List -- photo
--------------------------------------------- */
body.en {
	.c-list__photo {
		li {
			p {
				span {
					color: $main-color;
					font-family: $font-family-en;
					font-size: calc(12px + 2 * (100vw - 320px) / 960);
					font-weight: 700;
					letter-spacing: 0.05em;
					@include mq_down(xxs) {
						font-size: 3vw;
					}
				}
			}
		}
	}
}

/* ---------------------------------------------
	List -- table
--------------------------------------------- */
.c-list__table {
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.5em;
		background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
	}
	@include mq(sm) {
		display: flex;
		flex-flow: row wrap;
	}
	dt {
		color: $accent-color;
		letter-spacing: 0.5em;
		padding-bottom: 1em;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1.5em;
			background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
		}
		@include mq(sm) {
			width: 12em;
		}
		&.narrow {
			letter-spacing: 0.4em;
		}
	}
	dd {
		padding-bottom: 1em;
		@include mq(sm) {
			width: calc(100% - 12em);
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1.5em;
				background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
			}
		}
		ul {
			li {
				padding-bottom: 1em;
				@include mq_down() {
					span {
						display: block;
						padding-left: 1em;
					}
				}
				@include mq(sm) {
					&::before {
						content: "";
						display: block;
						width: 100%;
						height: 1px;
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 1em;
						background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
					}
					&:first-of-type {
						&::before {
							display: none;
						}
					}
				}
				&:last-of-type {
					padding-bottom: 0;
					margin-bottom: -0.5em;
				}
			}
		}
	}
}
