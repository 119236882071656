@charset "utf-8";

/* ---------------------------------------------
	Thanks
--------------------------------------------- */
.p-thanks {
	main {
		min-height: 700px;
		@include bg("home/topslider/wakkanupuri.jpg", no, ct, cover);
	}
}
.c-thanks {
	padding-top: 70px !important;
	&__wrap {
		max-width: 700px;
		padding: 50px 6vw 30px;
		margin: auto;
		text-align: center;
		position: relative;
		background: rgba($main-color, 0.8);
		color: $main-color2;
		border-radius: 0;
	}
	&__close {
		@include absolute_top_right($top: -65px);
		a {
			color: $main-color;
			@include font-size(30);
			font-weight: $font-weight-light;
		}
	}
	&__header {
		.c-head__m {
			line-height: 1.6;
			span {
				display: block;
			}
		}
	}
	&__message {
		p {
			font-size: calc(13px + 1 * (100vw - 320px) / 680);
			line-height: 2;
			@include mq_down(xxs) {
				font-size: 4vw;
			}
			span {
				display: inline-block;
			}
		}
	}
	&__footer {
		margin-top: 3em;
		p {
			line-height: 1.6;
			font-family: $font-family-base;
			font-size: calc(11px + 1 * (100vw - 320px) / 680);
		}
	}
}
