@charset "utf-8";

/* ------------------------------
	Button -- contact form
------------------------------ */
.c-btn__contact {
	display: block;
	width: 240px;
	height: 40px;
	color: $main-color;
	background: $main-bg-color;
	font-weight: $font-weight-bolder;
	margin-bottom: 10px;
	letter-spacing: 0.1em;
	line-height: 40px;
	@include font-size(14);
	background: $main-bg-color;
	text-align: center;
	margin: auto;
}
/* ------------------------------
	Button -- privacy policy
.c-btn__privacy {
	display: block;
	width: 222px;
	height: 16px;
	color: $main-color3;
	border-left: 1px solid $main-color3;
	border-right: 1px solid $main-color3;
	font-family: $font-family-en;
	font-weight: $font-weight-bold;
	@include font-size(13);
	letter-spacing: 0.1em;
	line-height: 16px;
	text-align: center;
	margin: 18px auto;

	@include mq(sm) {
		width: 280px;
	}

	@include mq(lg) {
		width: 320px;
		height: 20px;
		@include font-size(14);
		line-height: 24px;
	}
}
------------------------------ */

/* ------------------------------
	Link -- read more
------------------------------ */
.c-more {
	width: 120px;
	height: 2em;
	@include font-size(12);
	line-height: 1;
	border-bottom: 1px solid $main-color;
	padding-bottom: 1em;
	margin-top: 50px;
	position: relative;
	&::after {
		display: block;
		content: "";
		width: 0.5em;
		height: 0.5em;
		border-top: 1px solid $main-color;
		border-right: 1px solid $main-color;
		transform: rotate(45deg);
		@include absolute_bottom_right($bottom: 1.1em, $right: 3px);
		transition: right 0.5s;
	}
	&:hover {
		&::after {
			right: -3px;
		}
	}
	&.w {
		color: $main-color2;
		border-bottom-color: $main-color2;
		&::after {
			border-top-color: $main-color2;
			border-right-color: $main-color2;
		}
	}
}

/* ------------------------------
	Link -- external link
------------------------------ */
.c-link {
	position: relative;
	&::after {
		content: "";
		display: inline-block;
		width: 1em;
		height: 1em;
		@include bg("common/icon_link.svg", no, center top 2px, contain);
		margin-left: 8px;
		padding-bottom: 3px;
	}
}

/* ------------------------------
	Button -- more(news)
.c-btn__more {
	display: none;
	width: 150px;
	height: 3em;
	text-align: center;
	padding-top: 0.5em;
	border: 1px solid $main-color;
	float: right;
	margin-top: 100px;
	&.active {
		display: block;
	}
}
------------------------------ */
