@charset "utf-8";

/* ---------------------------------------------
	Footer
--------------------------------------------- */
.l-footer {
	padding-top: 80px;
	position: relative;
	z-index: 0;
	background: $main-bg-color2;
	@include mq_down() {
		padding-top: vwsp(80);
	}
	@include mq(lg) {
		padding-top: 156px;
	}

	&__upper {
		@include mq(lg) {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		.l-footer {
			&__navi {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				width: 65%;
				max-width: 722px;

				@include mq_down(lg) {
					display: none;
				}

				li {
					width: auto;
					@include font-size(15);
					line-height: 1.5;
					@include mq(xl) {
					}
					a {
						color: $main-color2;
						font-weight: $font-weight-bold;
						letter-spacing: 0.05em;
						span {
							&:nth-of-type(1) {
								font-family: $font-family-mincho;
								font-weight: $font-weight-base;
							}
							&:nth-of-type(2) {
								@include break;
								font-family: $font-family-en;
								font-weight: $font-weight-light;
								letter-spacing: 0.1em;
								@include font-size(13);
							}
						}
					}
				}
			}
			&__contact {
				width: 240px;
				margin-top: 5px;
				@include mq_down(lg) {
					margin: 5px auto 60px;
				}
				dl {
					display: flex;
					color: $main-color3;
					@include font-size(12);
					flex-flow: row wrap;
					justify-content: space-between;
					margin-top: 10px;
					dt {
						width: 6em;
						font-weight: $font-weight-bolder;
					}
					dd {
						width: calc(240px - 6em);
						text-align: right;
						a {
							display: inline-block;
							font-weight: $font-weight-bolder;
							margin-left: 0.5em;
						}
						&:nth-of-type(1) {
							a {
								color: $main-color3;
								&:hover {
									color: $main-color2 !important;
									font-weight: $font-weight-bolder;
									opacity: 1;
								}
								& ~ span {
									color: $main-color2 !important;
									font-weight: $font-weight-bolder;
								}
							}
						}
						&.c-list__sns {
							justify-content: flex-end;
							a {
								width: 20%;
								padding-right: 4px;
								img {
									width: 70%;
								}
								&:first-of-type {
									padding-right: 0;
									img {
										width: 57%;
									}
								}
								&:nth-of-type(2) {
									img {
										width: 62%;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&__lower {
		padding-bottom: 60px;
		@include mq_down() {
			padding-bottom: vwsp(30);
		}
		@include mq(lg) {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin-top: 10px;
			padding-bottom: 40px;
		}
		.l-footer {
			&__logo {
				width: 90%;
				max-width: 320px;
				height: 49px;
				margin: auto;
				@include mq(lg) {
					width: 35%;
					max-width: 320px;
					margin-left: 0;
				}
			}

			&__add {
				width: 100%;
				color: $main-color2;
				@include font-size(14);
				letter-spacing: 0.1em;
				margin-top: 57px;
				text-align: center;
				@include mq_down(xxs) {
					font-size: 4vw;
					letter-spacing: 0.05em;
					margin-top: vwsp(57);
				}
				@include mq(lg) {
					width: 40em;
					text-align: right;
					margin-top: 37px;
				}
				@include mq(xl) {
					width: calc(100% - 320px);
				}
				span {
					&:nth-of-type(1) {
						&::before {
							content: "運　営";
							display: inline-block;
							margin-right: 1em;
						}
						@include mq(lg) {
							display: block;
							margin-right: 0;
							text-align: left;
						}
						@include mq(xl) {
							display: inline;
							margin-right: 1em;
						}
					}
				}
			}
		}
	}

	&__copyright {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding-bottom: 77px;
		@include mq_down() {
			padding-top: vwsp(16);
		}
		@include mq(lg) {
			width: calc(100% - 12vw);
			max-width: $width-main;
			padding-top: 35px;
			margin: auto;
		}
		&::before {
			content: "";
			display: block;
			width: 90%;
			height: 1px;
			margin-left: 5%;
			margin-right: 5%;
			margin-bottom: 16px;
			background: $main-bg-color;
			@include mq(lg) {
				width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		p {
			color: $main-color3;
			font-family: $font-family-en;
			@include font-size(12);
			font-weight: $font-weight-bold;
			text-align: center;
			margin: auto;
			letter-spacing: 0.04em;
			@include mq_down(xs) {
				font-size: 3.2vw;
			}
			@include mq(lg) {
				@include font-size(12);
				margin: 0;
			}
			&:nth-of-type(1) {
				@include mq(lg) {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					width: 35%;
					max-width: 320px;
				}
			}
			a {
				color: $main-color3;
				@include font-size(12);
				font-weight: $font-weight-bold;
				letter-spacing: 0.15em;
				margin-right: 2em;
				@include mq_down(lg) {
					display: none;
				}
				&:last-of-type {
					margin-right: 0;
				}
				&:hover {
					text-decoration: none;
				}
			}
			br {
				display: none;
				@media screen and (max-width: 380px) {
					display: block;
				}
			}
		}
	}
	&__bottom {
		width: 100%;
		background: #000;
		padding-top: 62px;
		padding-bottom: 62px;
		@include mq(lg) {
			height: 200px;
		}
		.l-container {
			@include mq(lg) {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
			}
		}
		p {
			width: 13em;
			color: $main-color2;
			font-family: $font-family-en;
			@include font-size(12);
			letter-spacing: 0.1em;
			margin: auto;
			opacity: 0.8;
			@include mq(sm) {
				margin: 0;
			}
			@include mq(lg) {
				margin-top: 25px;
			}
		}
		.c-list__yado {
			width: calc(100% - 12vw);
			max-width: 238px;
			margin-top: 30px;
			margin-right: auto;
			margin-left: auto;
			@include mq(sm) {
				width: 100%;
				max-width: 100%;
				margin-right: 0;
			}
			@include mq(lg) {
				margin-top: 7px;
				width: calc(100% - 17em);
				max-width: 802px;
			}
			li {
				@include mq(sm) {
					width: 31%;
				}
			}
		}
	}
}

/* ---------------------------------------------
	[EN]Footer
--------------------------------------------- */
body.en {
	.l-footer {
		&__navi {
			li {
				a {
					span {
						&:nth-of-type(1) {
							font-family: $font-family-en;
							font-weight: $font-weight-light;
							letter-spacing: 0.1em;
						}
						&:nth-of-type(2) {
							display: none;
						}
					}
				}
			}
		}
		&__add {
			@include mq(lg) {
				width: 36em;
				position: relative;
			}
			span {
				overflow-wrap: break-word;
				letter-spacing: 0.01em;
				padding-right: 0.5em;
				margin-left: 0;
				display: inline-block;
				&:nth-of-type(1) {
					display: block;
					padding-right: 0;
					&::before {
						content: "Operated by";
						margin-right: 0.5em;
					}
					@include mq(lg) {
						width: 14.5em;
						margin-left: 1em;
					}
				}
				&:nth-of-type(2) {
					@include mq(lg) {
						//width: 22em;
						text-align: right;
					}
				}
				&:nth-of-type(5) {
					display: block;
					@include mq(lg) {
						@include absolute_top_right;
					}
				}
			}
		}
	}
}
