@charset "utf-8";
// =================================================
// Setting
// =================================================
// width -------------------------------------------
$width-browser: 1600px;
$width-base: 1340px;
$width-main: 1280px;
$width-content: 960px;

// font --------------------------------------------
$font-family-base: "Noto Sans JP", sans-serif;
$font-family-mincho: "Shippori Mincho", serif;
$font-family-en: "Century Gothic", "Avenir-Book", CenturyGothic, AppleGothic, sans-serif;
$font-family-en2: "Avenir-Book", CenturyGothic, AppleGothic, sans-serif;
$font-size-base: 14;
$font-family-monospace: monospace, serif;
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-xbold: 900;
$letter-spacing: 0.015em;
$line-height-base: 2;

// color -------------------------------------------
$main-color: #000;
$main-color2: #fff; // フッターメニュー
$main-color3: #999; // 見出しグレー文字,フッターLOCATION,SOCIAL文字色,ライン下メニュー＆コピーライト文字色
$main-color4: #ccc; // トップスライダー文字ベース色

$main-bg-color: #fff;
$main-bg-color2: #191919; // フッター背景, お問い合わせ帯黒,お問い合わせボタン背景
$main-bg-color3: #f6f6f6; // トップABOUT,トップSTAY各宿,EXPERIENCE背景
$main-bg-color4: #f0f0f0; // トップLOCATION,リンクエリア背景
$main-bg-color5: #000; // トップDINING背景

$accent-color: #820200; // お問い合わせ必須
$accent-color-fure: #a24341; // FUREカラー
$accent-color-rera: #96b049; // RERAカラー
$accent-color-ipe: #663414; // IPEカラー
$accent-color-travel: #817f48; // ACTIVITY見出し文字,WAKKA TRAVELバナー背景
$accent-color-shop: #35593d; // SHOPバナー背景
$accent-color-bbb: #2c5779; // WAKKA BBBバナー背景

$btn-nav-color: rgba(#000, 0.9);
$btn-contact-bg-color: #000;

$line-color: rgba(#231815, 0.4); // ドットライン
$line-color2: #000; // NEWSドットライン

$selection-color: #bbbbbb;
$scrollbar-face-color: #999;

$check: rgba(#fe7, 0.5);

// form color -------------------------------------------
$wht: #fff;
$blk: #000;
$inputbrdr: #000;
$inputbk: #fff;
$box-shadow: #999;
$colored: #efefef;
$achroma: #f8f8f8;
$placeholder: #999;
$wrn: #edd5de;
$must: #820200;
$err: #820200;
$ok: #0c0;
$submit1: #000;
$submit2: #fff;
$submit_txt: #fff;
$reset1: #fff;
$reset2: #1e1e1e;

// link --------------------------------------------
$link-color: #040000;
$link-font-weight: 400;
$link-decoration: none;
$link-hover-color: #999;
$link-hover-decoration: underline;

// table -------------------------------------------
$table-cell-padding: 1em;
$table-caption-color: #000;

// margin ------------------------------------------
$dt-font-weight: 300;
$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;
$label-margin-bottom: 0;

// other -------------------------------------------
$enable-pointer-cursor-for-buttons: true;
$imgDir: "../images/";
