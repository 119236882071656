@charset "utf-8";

/* ---------------------------------------------
	About
--------------------------------------------- */
.p-about {
	.c-area__link {
		background: $main-bg-color;
	}
}

/* ---------------------------------------------
	Privacy Policy
--------------------------------------------- */
.p-privacy {
	.c-head__sc {
		margin-bottom: 5em;
	}
	.u-note {
		font-size: 90%;
		margin-top: -2em;
	}
	.c-head__ss {
		margin-top: 4em;
	}
}

/* ---------------------------------------------
	Sitemap
--------------------------------------------- */
.p-sitemap {
	&__list {
		margin: 150px auto 100px;
		width: 15em;
		@include mq(sm) {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			width: 40em;
		}
		.p-sitemap__group {
			margin-right: auto;
			margin-bottom: 40px;
			margin-left: auto;
			@include mq(sm) {
				margin-right: 0;
				margin-left: 0;
			}
			&:nth-of-type(1) {
				@include mq(sm) {
					width: 17em;
				}
			}
			&:nth-of-type(2) {
				@include mq(sm) {
					width: 10em;
				}
			}
		}
		a {
			display: block;
			width: 100%;
			margin-bottom: 40px;
			span {
				display: inline-block;
				position: relative;
				&::after {
					content: "";
					display: inline-block;
					width: 0;
					height: 1px;
					background: $main-color;
					@include absolute_bottom_left;
					transition: 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				}
			}
			&:hover {
				span::after {
					width: 100%;
				}
			}
			&.sns {
				padding-left: 40px;
				@include bg("n", no, lc, 20px auto);
				&.instagram {
					background-image: url($imgDir + "common/icon_instagram.svg");
					@include mq_down(sm) {
						margin-top: 100px;
					}
				}
				&.facebook {
					background-image: url($imgDir + "common/icon_facebook.svg");
				}
				&.youtube {
					background-image: url($imgDir + "common/icon_youtube.svg");
				}
				&.blog {
					background-image: url($imgDir + "common/icon_blog.svg");
				}
			}
		}
		.p-sitemap__sub {
			margin-top: -30px;
			margin-bottom: 40px;
			margin-left: 2em;
			a {
				margin-bottom: 1em;
				&::before {
					content: "ー";
					margin-right: 1em;
				}
			}
		}
	}
}
