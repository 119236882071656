@charset "utf-8";

/* ---------------------------------------------
	Address
--------------------------------------------- */
address {
	color: $main-color2;
	span {
		color: $main-color2;
		display: inline-block;
		margin-left: 10px;
		&:nth-of-type(1),
		&:nth-of-type(3) {
			@include mq_down() {
				display: block;
			}
		}
		a {
			display: inline;
			color: $main-color2;
		}
	}
}

/* ---------------------------------------------
	[EN]Address
--------------------------------------------- */
body.en {
	.c-nav__global__wrap {
		address {
			span {
				&:nth-of-type(1) {
					display: block;
				}
			}
		}
	}
}
/* ---------------------------------------------
	Box
--------------------------------------------- */
.c-box {
	background: $main-bg-color;
	&.gry {
		background: $main-bg-color4;
	}
	&.lgy {
		background: $main-bg-color3;
	}
	&.border {
		border-style: solid;
		border-width: 1px;
		&.travel {
			border-color: $accent-color-travel;
		}
	}
}
