@charset "utf-8";

/* ------------------------------
	Navigation -- header sub
------------------------------ */
.c-nav__header__sub {
	display: none;
	@include mq(lg) {
		width: 525px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-top: 20px;
		position: absolute;
		right: calc((30px + 40 * (100vw - 320px) / 1280) + 40px);
	}
	li {
		a {
			@include font-size(11);
			opacity: 0.5;
			&:hover {
				opacity: 1;
			}
		}
		dl {
			position: relative;
			dt {
				cursor: pointer;
				padding-right: 1.5em;
				text-align: right;
				a {
					width: calc(100% + 1em);
					position: relative;
					&::after {
						content: "";
						display: block;
						width: 0;
						height: 0;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 8px solid $main-color;
						opacity: 0.8;
						@include absolute_top_right($top: 8px, $right: -1.5em);
					}
				}
			}
			dd {
				@include absolute_top_left($top: 1.4em);
				line-height: 1.4;
				opacity: 0;
				a {
					width: 100%;
					letter-spacing: 0;
				}
			}
			&:hover > dd {
				opacity: 1;
			}
		}
		&:nth-child(1) {
			width: 4em;
		}
		&:nth-child(2) {
			width: 4em;
			dd {
				width: 8.5em;
				left: 1.2em;
			}
		}
		&:nth-child(3) {
			width: 4em;
			dd {
				left: 0.5em;
			}
		}
		&:nth-child(4) {
			width: 2em;
			dd {
				width: 3.5em;
				left: 0.3em;
			}
		}
		&:nth-child(5) {
			height: 20px;
			margin-top: -5px;
			margin-left: 1em;
			margin-right: 0;
			a {
				width: 13em;
				display: inline-block;
				background: $main-color;
				color: $main-color2;
				@include font-size(10);
				font-weight: $font-weight-bold;
				border: 1px solid $main-color;
				opacity: 1;
				text-align: center;
				&:hover {
					color: $main-color;
				}
			}
		}
	}
	.open & {
		opacity: 0;
	}
}

/* ------------------------------
	Navigation -- header main
------------------------------ */
.c-nav__header__main {
	display: none;
	@include mq(lg) {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		position: absolute;
		top: calc(55% - 1em);
		right: calc((30px + 40 * (100vw - 320px) / 1280) + 15px);
		width: 50%;
		max-width: 613px;
		li {
			width: 7.5em;
			&:nth-child(1),
			&:nth-child(3) {
				width: 4em;
			}

			a {
				font-size: calc(12px + 3 * (100vw - 1000px) / 960);
				line-height: 1;
				padding-top: 1em;
				padding-bottom: 1em;
				text-align: center;
				transition: display 1s linear;
				@include mq(xxxl) {
					@include font-size(14);
				}
				span {
					&:nth-of-type(1) {
						display: block;
					}
					&:nth-of-type(2) {
						letter-spacing: 0;
						display: none;
					}
				}
				&:hover {
					opacity: 1;
					span {
						&:nth-of-type(1) {
							display: none;
						}
						&:nth-of-type(2) {
							display: block;
						}
					}
				}
			}
			&.current {
				a {
					font-weight: $font-weight-bolder;
				}
			}
			&:nth-child(3) {
				a {
					span:nth-of-type(1) {
						margin-left: -1em;
					}
				}
			}
		}
	}
	.open & {
		opacity: 0;
	}
}

/* ------------------------------
	[EN]Navigation -- header main
------------------------------ */
body.en {
	.c-nav__header__main {
		@include mq(lg) {
			li {
				a {
					&:hover {
						span {
							display: block;
							color: $main-color3;
						}
					}
				}
			}
		}
	}
}

/* ------------------------------
	Navigation -- global
------------------------------ */
.c-nav__global__wrap {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 0;
	background: #000;
	visibility: hidden;
	opacity: 0;
	overflow-y: hidden;
	scrollbar-face-color: $scrollbar-face-color;
	scrollbar-track-color: $scrollbar-face-color;
	-webkit-overflow-scrolling: touch;
	transition: all 0.4s ease-in-out;
	z-index: 0;

	.open & {
		height: 100vh;
		background: rgba(#000, 0.95);
		visibility: visible;
		overflow-y: scroll;
		opacity: 1;
		z-index: 10;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 80px;
			position: fixed;
			top: 0;
			left: 0;
			background: rgba(#000, 0.9);
		}
	}
	.c-btn__contact {
		margin: auto;
	}
	address {
		width: calc(100% - 12vw);
		max-width: 802px;
		font-size: calc(13px + 2 * (100vw - 320px) / 960);
		@include mq_down(xxs) {
			font-size: 4vw;
		}
		margin: 5px auto;
		span {
			display: inline-block;
			width: auto;
			margin-left: 0;
			margin-right: 1em;
			&:first-of-type {
				@include mq_down(xs) {
					display: block;
				}
			}
			&:nth-of-type(3) {
				display: inline-block;
			}
		}
	}
}

.c-nav__global {
	width: calc(100% - 6vw);
	max-width: 9em;
	margin: 90px auto 72px;
	@include mq(sm) {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		max-width: 900px;
		margin-top: 200px;
		margin-bottom: 10px;
	}

	&__logo {
		width: 60%;
		max-width: 280px;
		height: auto;
		margin-top: 40px;
		margin-right: auto;
		margin-left: auto;
		@include mq(lg) {
			width: 45%;
			max-width: 340px;
		}
	}

	li {
		width: auto;
		list-style: none;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 50px;

		a {
			display: block;
			color: $main-color2;
			line-height: 1.5;
			transition: all 0.3s ease-in;
			@include mq_down(sm) {
				text-align: center;
			}

			span {
				&:nth-of-type(1) {
					font-family: $font-family-mincho;
					font-weight: $font-weight-bold;
					font-size: calc(13px + 1 * (100vw - 320px) / 447);
					@include mq_down(xxs) {
						font-size: 3.5vw;
					}
				}
				&:nth-of-type(2) {
					@include break;
					font-family: $font-family-en;
					font-size: calc(12px + 1 * (100vw - 320px) / 447);
					letter-spacing: 0.1em;
					@include mq_down(xxs) {
						font-size: 3vw;
					}
				}
			}

			&:hover {
				color: $link-hover-color;
				opacity: 1;
			}
		}
		&.current a {
			color: $link-hover-color;
			opacity: 1;
		}
	}
}

/* ------------------------------
	[EN]Navigation -- global
------------------------------ */
body.en {
	.c-nav__global {
		li {
			a {
				span {
					&:nth-of-type(1) {
						font-family: $font-family-en;
					}
				}
			}
		}
	}
}

/* ------------------------------
	Navigation -- contact
------------------------------ */
.c-nav__global__contact {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: calc(100% - 12vw);
	max-width: 560px;
	margin: auto;
	@include mq_down(xxs) {
		flex-flow: column wrap;
		margin-bottom: -20px;
	}
	li {
		width: 47%;
		max-width: 240px;
		@include mq_down(xxs) {
			width: 100%;
			margin: 0 auto 20px;
		}
	}
	a {
		background: $main-bg-color;
		color: $main-color;
		font-size: calc(12px + 2 * (100vw - 320px) / 960);
		font-weight: $font-weight-bolder;
		text-align: center;
		padding: 0.5em;
		@include mq_down(xxs) {
			font-size: 4vw;
		}
	}
}
/* ------------------------------
	Navigation -- subnav
------------------------------ */
.c-nav__global__subnav {
	display: flex;
	flex-flow: row wrap;
	width: calc(100% - 12vw);
	max-width: 802px;
	margin-top: 80px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	@include mq() {
		margin-top: 140px;
	}
	a {
		display: inline-block;
		color: $main-color3;
		font-family: $font-family-en;
		font-size: calc(11px + 1 * (100vw - 320px) / 960);
		font-weight: $font-weight-bolder;
		letter-spacing: 0.1em;
		margin-right: 2em;
		@include mq_down(xxs) {
			font-size: 3.5vw;
		}
		@include mq(lg) {
			margin-right: 3em;
		}
	}
	li {
		&:last-child {
			width: 100%;
			@include mq(sm) {
				//width: 10em;
				width: 5.5em;
				text-align: right;
				@include absolute_bottom_right;
			}
			a {
				color: $main-color3;
				margin-right: 0;
				&:hover {
					color: $main-color2 !important;
					font-weight: $font-weight-bolder;
					text-decoration: none;
					opacity: 1;
				}
				& ~ span {
					color: $main-color2 !important;
					font-weight: $font-weight-bolder;
				}
			}
		}
	}
}

/* ------------------------------
	Navigation -- sns
------------------------------ */
.c-nav__global__sns {
	display: flex;
	flex-flow: row wrap;
	width: calc(100% - 12vw);
	max-width: 802px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 1em;
	p {
		width: 5em;
		color: $main-color3;
		font-family: $font-family-en;
		font-size: calc(11px + 1 * (100vw - 320px) / 960);
		font-weight: $font-weight-bolder;
		letter-spacing: 0.1em;
		line-height: 1.6;
		@include mq_down(xxs) {
			font-size: 3.5vw;
		}
	}
	.c-list__sns {
		width: 192px;
		a {
			width: 18px;
			margin-left: 30px;
		}
	}
}
/* ------------------------------
	Navigation -- link1
------------------------------ */
.c-nav__global__link1 {
	display: flex;
	flex-flow: column wrap;
	margin-top: 40px;
	margin-right: auto;
	margin-left: auto;
	width: calc(100% - 12vw);
	max-width: 240px;

	@include mq(sm) {
		flex-flow: row wrap;
		justify-content: space-between;
		max-width: 802px;
	}
	li {
		width: 100%;
		max-width: 240px;
		margin-bottom: 30px;
		position: relative;
		transition: all 0.4s ease-in-out;
		@include mq(sm) {
			width: 31%;
		}
		p {
			margin: 0;
			&:first-of-type {
				width: 100%;
				height: 60px;
				position: relative;
				img {
					width: auto;
					@include centering;
				}
			}
			&:last-of-type {
				color: $main-color2;
				@include font-size(13);
				&::after {
					content: "";
					display: inline-block;
					width: 1em;
					height: 1em;
					@include bg("common/icon_link_w.svg", no, center top 2px, contain);
					margin-left: 8px;
					margin-top: 10px;
				}
			}
		}
		&:nth-child(1) {
			p:first-of-type {
				background-color: $accent-color-bbb;
				img {
					height: 44px;
					@include mq() {
						height: 50px;
					}
				}
			}
		}
		&:nth-child(2) {
			p:first-of-type {
				background-color: $accent-color-travel;
				img {
					height: 24px;
					@include mq() {
						height: 30px;
					}
				}
			}
		}
		&:nth-child(3) {
			p:first-of-type {
				background-color: $accent-color-shop;
				img {
					height: 30px;
					@include mq() {
						height: 36px;
					}
				}
			}
		}
		&:hover {
			p {
				opacity: 0.7;
			}
		}
	}
}
/* ------------------------------
	Navigation -- link2
------------------------------ */
.c-nav__global__link2 {
	width: calc(100% - 12vw);
	max-width: 238px;
	margin-top: 50px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 100px;
	@include mq(sm) {
		max-width: 802px;
		margin-top: 100px;
	}
	p {
		width: 100%;
		color: $main-color2;
		font-family: $font-family-mincho;
		letter-spacing: -0.1em;
		margin-bottom: 5px;
	}
}
/* ------------------------------
	[EN]Navigation -- link2
------------------------------ */
body.en {
	.c-nav__global__link2 {
		p {
			font-size: 80%;
			letter-spacing: 0.05em;
			margin-bottom: 1em;
		}
	}
}
/* ------------------------------
	Navigation -- button
------------------------------ */
.c-nav__btn {
	cursor: pointer;
	outline: 0;
	position: fixed;
	right: -10px;
	z-index: 100;
	@include mq_down(lg) {
		right: 2vw;
		top: 49%;
	}
	@include mq(xxl) {
		right: -20px;
	}
	@include mq(xxl) {
		right: -30px;
	}

	div {
		position: relative;
		width: calc(30px + 50 * (100vw - 320px) / 1280);
		min-width: 30px;
		max-width: 80px;
		height: 20px;
		outline: 0;
		top: 50%;
		transform: translateY(-50%);
		@include mq(lg) {
			width: 60px;
			top: 32px;
		}
		@include mq(xxl) {
			width: 80px;
		}

		span {
			display: block;
			width: 100%;
			height: 1px;
			background: $main-color;
			position: absolute;
			left: 0;
			transition: all 0.25s;

			.l-header.open & {
				background: $main-color2;
			}

			&:nth-of-type(1) {
				top: 0;

				.l-header.open & {
					top: calc(100% - 1px);
					transform: rotate(15deg);
				}
			}

			&:nth-of-type(2) {
				top: calc(50% - 1px);

				.l-header.open & {
					opacity: 0;
				}
			}

			&:nth-of-type(3) {
				bottom: 0;

				.l-header.open & {
					transform: rotate(-15deg);
				}
			}
		}
	}
	.l-header.open & {
		position: fixed;
		top: calc(35px + 43 * (100vw - 320px) / 960);
		z-index: 100;
		@include mq(lg) {
			top: 0;
		}
	}
}
